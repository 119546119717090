import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO path={location.pathname} title="404: Not found" />
    <div>
      <h2 className="text-2xl font-bold inline-block my-8 p-3">
        Aww, the page seems to be missing.
      </h2>
    </div>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFoundPage;
